import React from 'react';
import Button from '../components/Button/Button';
import Layout from '../components/layout/Layout';
import SEO from '../components/seo';
import { Link } from 'gatsby';
import { withPrefix } from 'gatsby';
import poster from '../img/posterwebinar2.png';

const primecolor = {
  color : '#0C41B8'
}

const SecondEvent = () => (
  <Layout withRegister>
    <SEO title="Webinar" />
    <div className="p-3 md:p-0 md:container md:mx-auto w-full grid grid-cols-1 md:grid-cols-2 gap-12">
      <div className="p-2 md:p-10">
        <img src={ poster } alt="poster" className="mx-auto" />
      </div>
      <div className="p-2 md:p-10">
        <p className="font-bold text-2xl md:text-4xl">Webinar - Unearthing Potential: Next Big Thing of RE</p><br />
        <p className="font-bold text-2xl md:text-2xl" style={ primecolor }>Webinar</p>
        <p className="text-base md:text-lg mt-4">
          Saturday, 10 July 2021<br />
          09.00 - 16.00 / GMT+7
        </p>
        <p className="font-bold text-2xl md:text-2xl mt-8" style={ primecolor }>Description :</p>
        <p className="text-base md:text-lg">
        A webinar to disseminate information on new and renewable energy, with the theme Unearthing Potential: Next Big Thing of RE, with the subthemes:
        <div className="text-base md:text-lg ml-4">
          <ul className="list-disc">
          <li>Gas Power : A Start For Zero Emission Energy Transition</li>
          <li>Renewable Energy : Powering Indonesia Through RE</li>
          <li>Grid Evolutions : Grid Transitions and Challenges</li>
          <li>Mobilizing Clean Energy Finance and Investment to Support Energy Transition</li>
        </ul>
        </div>
        
        </p>
        <p className="font-bold text-2xl md:text-2xl mt-8" style={ primecolor }>Benefit :</p>
        <div className="text-base md:text-lg ml-4">
          <ul className="list-disc">
            <li>Update knowledge about the progress of new renewable energy development in Indonesia from experts</li>
            <li>Get free e-certificate</li>
          </ul>
        </div>
        <div className="mt-5">
            <a href="https://bit.ly/YouthIdeaCompetitionWebinar2Registration" target="_blank">
                <Button className="rounded-full">Register Now</Button>
            </a>
        </div>
      </div>
    </div>
  </Layout>
);

export default SecondEvent;
